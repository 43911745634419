<template>
  <v-container :id="ctaPayload?.slug" class="pt-8 pb-8 pt-md-16 pb-md-0 px-0 position-relative">
    <v-row class="align-center">
      <v-col
        cols="12"
        md="6"
        class="mb-16"
        :class="{ 'order-last': ctaPayload?.box_position === BoxOrPicturePosition.Left }">
        <H2Number
          :number="ctaPayload?.title_has_number ? `${ctaPayload?.title_number}` : ''"
          :title="`${activeTranslation?.title}`" />

        <div v-if="ctaPayload?.subtitle" class="custom-subtitle">
          {{ activeTranslation?.subtitle }}
        </div>
        <div class="text-cms-content" v-html="sanitizeHtml(activeTranslation?.text_content)" />
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="d-flex pb-0 flex-column"
        :class="{
          'justify-start': ctaPayload?.box_position === BoxOrPicturePosition.Left && !smAndDown,
          'justify-end': ctaPayload?.box_position === BoxOrPicturePosition.Right && !smAndDown,
          'justify-center': smAndDown,
          'pa-0': smAndDown,
        }">
        <CdeCard
          :rounded="smAndDown ? '0' : 'lg'"
          class="pa-md-6 pb-16 mb-16 mb-md-3"
          width="588"
          max-width="100vw"
          color="secondary"
          align="center">
          <v-card-item>
            <v-card-title class="white--text custom-title">
              {{ activeTranslation?.cta_title }}
            </v-card-title>

            <v-card-text class="text-h4">
              {{ activeTranslation?.cta_content }}
            </v-card-text>
          </v-card-item>

          <v-row v-if="ctaPayload?.cta_type === CTAType.ZipAndWaste" class="position-relative">
            <v-col cols="1" class="px-0 px-md-3">
              <v-img
                :src="IconTriangle"
                width="50"
                height="50"
                class="custom-triangle-icon"
                :alt="t('arrow')" />
            </v-col>

            <v-col cols="12" class="pr-0 px-md-3">
              <v-menu
                id="menu-zip-cta"
                v-model="zipDropdown"
                role="combobox"
                aria-expanded="false"
                aria-controls="zip-input-ordernow"
                transition="slide-y-transition">
                <template #activator="{ props }">
                  <v-text-field
                    id="zip-input-ordernow"
                    v-model="postalCodeString"
                    width="80%"
                    :placeholder="t('your_zip')"
                    :error="errorMarker"
                    maxlength="5"
                    class="mb-3 mt-4"
                    v-bind="props"
                    @focus="resetPayloadZipcode"
                    @input="checkZipInput" />
                </template>
                <v-list v-if="cities.length > 0">
                  <v-list-item v-for="item in cities" :key="item?.zip" @click="selectZipcode(item)">
                    <v-list-item-title>
                      {{ `${item?.zip}, ${item?.city}` }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>

          <v-row v-if="ctaPayload?.cta_type === CTAType.ZipAndWaste">
            <v-col class="pr-0 px-md-3">
              <ClientOnly>
                <GlobalWasteDropdown type="textCta" @selected-waste="setPath" />
              </ClientOnly>
            </v-col>
          </v-row>

          <v-card-actions
            v-if="ctaPayload?.cta_type === CTAType.ZipAndWaste"
            class="justify-center">
            <v-tooltip top :disabled="!wrongZipcode && path !== null && path !== ''" open-on-click>
              <template #activator="{ props }">
                <div class="ml-3 ml-md-0" v-bind="props">
                  <ButtonPrimary class="mt-4" @click="goToPage()">
                    {{ ctaPayload?.button_label }}
                  </ButtonPrimary>
                </div>
              </template>
              <span v-if="wrongZipcode">{{ t('enter_valid_zip') }}</span>
              <span v-if="!wrongZipcode && (path === null || path === '')">
                {{ t('please_choose_waste_type') }}
              </span>
            </v-tooltip>
          </v-card-actions>
          <v-card-actions
            v-if="ctaPayload?.cta_type !== CTAType.ZipAndWaste"
            class="justify-center">
            <ButtonPrimary class="mt-4" @click="scrollTo(ctaPayload?.button_destination)">
              {{ ctaPayload?.button_label }}
            </ButtonPrimary>
          </v-card-actions>
        </CdeCard>
        <v-img
          :alt="t('cloud')"
          src="https://epd.directus.app/assets/ba650a6e-5a68-4421-bc45-03472d08e862?format=webp&width=200"
          width="200"
          width-md="276"
          class="custom-position-right-up align-self-end align-self-lg-center" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import IconTriangle from '@/assets/icons/triangle.svg'
import type { LocaleObject } from '@nuxtjs/i18n'
import { useDisplay, useGoTo } from 'vuetify'
import { BoxOrPicturePosition, CTAType } from '~/types/enums'
import type { City, CityApiResponse, Fraction, TextCTA, TextCTATranslation } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'
const goTo = useGoTo()
const { locale, locales, t } = useI18n()
const props = defineProps<{
  ctaPayload: TextCTA
}>()

const { ctaPayload } = toRefs(props)
const zipDropdown = ref(false)

const currentLocaleIso = ref<string | undefined>(
  locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language,
)

const activeTranslation = ref<TextCTATranslation | undefined>(
  ctaPayload.value.translations?.find(
    (t: TextCTATranslation) => t.languages_code === currentLocaleIso.value,
  ),
)

const { smAndDown, mdAndUp } = useDisplay()

const containerStore = useContainerStore()
await containerStore.getContainerData()
const offerStore = useOfferStore()
const { currentCity } = storeToRefs(offerStore)

const wrongZipcode = ref(true)
const postalCodeString = ref('')
const postalCode = ref('')
const community = ref<number | null>(null)
const cities = ref<CityApiResponse[]>([])
const city = ref('')
const path = ref('')
const { checkZipcode } = useApiWrapper()
const errorMarker = ref(false)
const rules = {
  required: (value: string) => !!value || 'Field is required',
  zip: (value: string) =>
    (value.length === 5 && [...value].every(item => !isNaN(parseInt(item, 10)))) ||
    'Postal code must be 5 digits',
}

const setPath = (waste: Fraction) => {
  offerStore.setCurrentFractionId(waste.id)
  path.value = waste.shop_slug
}

const goToPage = async () => {
  if (rules.zip(postalCode.value) && wrongZipcode.value === false && path.value !== '') {
    offerStore.setCurrentCity({
      zip: postalCode.value,
      name: city.value,
      community: community.value as number,
    })
    return await navigateTo('/' + path.value)
  }
}
const checkZipInput = () => {
  cities.value = []
  if (postalCodeString.value.length < 3 || postalCodeString.value.length > 5) {
    return
  }
  checkZipcode(postalCodeString.value).then((response: any) => {
    if (!response || response === null) {
      return
    } else {
      cities.value = response as CityApiResponse[]
      if (cities.value.length < 1) {
        wrongZipcode.value = true
        errorMarker.value = true
      } else {
        errorMarker.value = false
        if (cities.value.length === 1 && postalCode.value !== cities.value[0]?.zip) {
          selectZipcode(cities.value[0])
          zipDropdown.value = false
        } else {
          zipDropdown.value = true
        }
      }
    }
  })
}
const selectZipcode = (response: CityApiResponse) => {
  wrongZipcode.value = false
  errorMarker.value = false
  postalCode.value = response?.zip as string
  city.value = response?.city as string
  community.value = response?.community as number
  postalCodeString.value = `${response?.zip}, ${response?.city}`
}
const resetPayloadZipcode = () => {
  postalCode.value = ''
  city.value = ''
  community.value = null
  postalCodeString.value = ''
  wrongZipcode.value = true
  errorMarker.value = false
}

onNuxtReady(() => {
  const previousSelectedCity = currentCity.value as City
  if (previousSelectedCity?.zip) {
    cities.value = [
      {
        zip: previousSelectedCity.zip,
        city: previousSelectedCity.name,
        community: previousSelectedCity.community,
      },
    ]
    postalCode.value = previousSelectedCity.zip
    city.value = previousSelectedCity.name
    community.value = previousSelectedCity.community
    postalCodeString.value = `${previousSelectedCity.zip}, ${previousSelectedCity.name}`
    wrongZipcode.value = false
    errorMarker.value = false
  }
})

const scrollTo = async (target: string | null) => {
  if (
    !target ||
    typeof target !== 'string' ||
    target === null ||
    target.length === 0 ||
    target.split('#')[1]?.length === 0
  ) {
    goTo(0)
  } else if (target.includes('http') || target.includes('www')) {
    await navigateTo(target, {
      open: {
        target: '_blank',
      },
    })
  } else {
    goTo(target, {
      container: 'html',
      duration: 300,
      easing: 'easeInOutCubic',
      offset: 1,
    })
  }
}

if (
  activeTranslation.value?.text_content?.includes('<table') &&
  activeTranslation.value?.text_content?.includes('border="')
) {
  activeTranslation.value.text_content = activeTranslation.value?.text_content.replace(
    /border=".*?"/g,
    '',
  )
}

const tableSpanArray = ref<HTMLSpanElement[]>([])

function formatTable() {
  if (activeTranslation.value?.text_content?.includes('<table') && !mdAndUp) {
    const parser = new DOMParser()

    const doc: Document = parser.parseFromString(
      activeTranslation.value?.text_content ?? '',
      'text/html',
    )

    const spanElements: NodeListOf<HTMLSpanElement> = doc.querySelectorAll('table span')

    tableSpanArray.value = Array.from(spanElements)

    const tableElement = doc.querySelector('table')
    if (tableElement) {
      tableElement.remove()
    }

    activeTranslation.value.text_content = doc.body.innerHTML
  }
}

onMounted(() => {
  formatTable()
  window.addEventListener('resize', formatTable)
})

onUnmounted(() => {
  window.removeEventListener('resize', formatTable)
})
</script>

<style scoped lang="scss">
:deep(table, tr, td, th) {
  border: none !important;
}
:deep(p) {
  padding: 1rem 0;
}

.custom-title {
  font-weight: 800;
  font-size: 40px;
  letter-spacing: -1px !important;
  white-space: wrap;
}

.custom-position-right-up {
  margin-top: -50px;
}

.custom-triangle-icon {
  position: absolute;
  bottom: 25px;
  left: 34px;
  z-index: 100;

  @media (max-width: 599px) {
    left: 16px;
  }
}
</style>
